import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { toWeightUnit } from '@/helpers/unit';
import { useQcMode } from '@/hooks/use-qcMode';
import AdditionalItems from './AdditionalItems';
import RefurbishmentInfo from './RefurbishmentInfo';
import { ErrorType } from '../QcPanel/QcPanel';

const MAX_LENGTH = 300;

export default function AdditionalInfo({
  isRequired,
  errors,
}: {
  isRequired: boolean;
  errors?: ErrorType[];
}) {
  const { t } = useTranslation();

  const {
    agentComment,
    setAgentComment,
    weight,
    setWeight,
    hallmarks,
    setHallmarks,
    agentPublicComment,
    setAgentPublicComment,
  } = useQualityControlTool();
  const { isViewMode } = useQcMode();

  const weightError = useMemo(() => {
    if (weight) return undefined;
    return errors && errors.find((e) => e.field === 'weight');
  }, [errors, weight]);

  const hallmarkError = useMemo(() => {
    if (hallmarks) return undefined;
    return errors && errors.find((e) => e.field === 'hallmarks');
  }, [errors, hallmarks]);

  return (
    <>
      <AdditionalItems isViewMode={isViewMode} />
      <RefurbishmentInfo isViewMode={isViewMode} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          padding: '1rem',
        }}
      >
        <TextField
          data-testid="weight_input_text"
          id="weight"
          label={toWeightUnit(t('QC_V2.DEFECT.WEIGHT'))}
          type="number"
          placeholder={t('QC_V2.MAIN.WEIGHT_PLACEHOLDER')}
          value={weight}
          sx={{ mb: 2 }}
          required={isRequired}
          error={weightError?.field === 'weight'}
          helperText={weightError?.message}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isViewMode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWeight(event.target.value);
          }}
        />
        <TextField
          data-testid="hallmarks_input_text"
          id="hallmark"
          label={t('QC_V2.MAIN.HALLMARKS')}
          placeholder={t('QC_V2.MAIN.HALLMARK_PLACEHOLDER')}
          value={hallmarks}
          sx={{ mb: 2 }}
          required={isRequired}
          error={hallmarkError?.field === 'hallmarks'}
          helperText={hallmarkError?.message}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isViewMode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHallmarks(event.target.value);
          }}
        />
        <TextField
          data-testid="internal_comment_input_text"
          id="agentComment"
          label={t('QC_V2.MAIN.QC_INTERNAL_COMMENT')}
          placeholder={t('QC_V2.MAIN.COMMENT_PLACEHOLDER')}
          value={agentComment}
          multiline
          sx={{ mb: 2 }}
          disabled={isViewMode}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setAgentComment(event.target.value);
          }}
        />

        <TextField
          data-testid="public_comment_input_text"
          id="agentPublicComment"
          label={t('QC_V2.MAIN.QC_PUBLIC_COMMENT')}
          placeholder={t('QC_V2.MAIN.COMMENT_PLACEHOLDER')}
          value={agentPublicComment}
          multiline
          disabled={isViewMode}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.substring(0, MAX_LENGTH);
            setAgentPublicComment(value);
          }}
        />
      </Box>
    </>
  );
}
