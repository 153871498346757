import Box from '@mui/material/Box';
import { useProduct } from '@/hooks/use-product';
import ProductImage, { MAX_VISIBLE_IMAGE_COUNT } from './ProductImage';
import { ProductInfo } from './ProductInfo';
import './ProductInfo.scss';

export const GeneralInfo = () => {
  const { product } = useProduct();

  if (!product || !product.id) return null;

  // filter out the first three images from product.images
  const displayedImgs = (product.images || []).slice(
    0,
    MAX_VISIBLE_IMAGE_COUNT
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        data-testid="product_info_images"
        sx={{
          width: '100%',
          overflowX: 'auto',
        }}
      >
        {displayedImgs.map((img, index) => (
          <ProductImage
            key={index}
            imagePath={img}
            index={index}
            count={
              index === displayedImgs.length - 1 ? product.images.length : 0
            }
          />
        ))}
      </Box>

      <ProductInfo />
    </>
  );
};
